import React, { useState } from "react"
import Layout from "../../components/_App/layout"
import SEO from "../../components/_App/seo"
import Footer from "../../components/_App/Footer"
import PageBanner from "../../components/Common/PageBanner"
import * as Icon from "react-feather"
import { graphql, Link } from "gatsby"
import NavbarNew from "../../components/NavbarNew"
import GetInTouch from "../../components/get-in-touch"
import bannerImage from "../../assets/images/home-banner2.jpg"

const Portfolio = ({ data }) => {
  const [filteredByIndustry, setFilteredByIndustry] = useState("")
  const dataList = data?.caseStudies?.nodes.filter(
    (node: any) => node.relativeDirectory.search("/") === -1
  )
  const filteredDatalist = filteredByIndustry
    ? dataList.filter(
        list => list?.childMdx?.frontmatter?.industry === filteredByIndustry
      )
    : dataList

  const Industries = [
    ...new Set(
      dataList.map((list: any) => list?.childMdx?.frontmatter?.industry)
    ),
  ]

  return (
    <Layout>
      <SEO
        title="Android Native | Mobile App Development | Tarkashilpa"
        description="Find our Web & Mobile App Development projects, they are outcome of our dedicated teams efforts which provide excellent services and making client project success."
        keywords="Web Application Development Services Company Mobile Application Development Services Company"
        url={`/our-work`}
        ogTwitterCardType="summary"
        ogImage="bannerImage"
      />

      <NavbarNew />

      <PageBanner pageTitle="Our Work" />

      <div className="features-area ptb-40 bigdata-feedback-area">
        <div className="container">
          <div className="section-title mb-40">
            <p>
              We had helped our clients resolve their business challenges with
              tailor made software solution that best fit their requirements and
              achive their business objectives. Here are few of the sucess
              stories...
            </p>
          </div>

          <div className="row justify-content-center">
            <div className="col-12">
              <div className="filter-case-study">
                <p>Industry</p>
                <select
                  className="filter-box"
                  onChange={(e: any) => setFilteredByIndustry(e.target.value)}
                >
                  <option value={""}>All</option>
                  {dataList &&
                    dataList.length > 0 &&
                    Industries.map((industry: any, index: number) => (
                      <option key={index + 1} value={industry}>
                        {industry}
                      </option>
                    ))}
                </select>
              </div>
              {dataList &&
                dataList.length > 0 &&
                filteredDatalist.map((list: any, index: number) => (
                  <div
                    className="single-features portfolio-list case-study-tile"
                    key={index + 1}
                  >
                    <div className="row align-items-start m-gap">
                      <div className="col-lg-4 col-md-4">
                        <img
                          src={list?.childMdx?.frontmatter?.thumbImg?.publicURL}
                          alt={list?.childMdx?.frontmatter?.thumbImgAlt}
                        />
                      </div>
                      <div className="col-lg-8 col-md-8">
                        <h3>
                          <Link to={list?.childMdx?.slug}>
                            {list?.childMdx?.frontmatter?.title}
                          </Link>
                        </h3>
                        <p>{list?.childMdx?.frontmatter?.summary}</p>
                        <Link
                          to={list?.childMdx?.slug}
                          className="learn-more-btn"
                        >
                          <Icon.PlusCircle /> Know More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <GetInTouch />
      <Footer />
    </Layout>
  )
}

export default Portfolio
export const query = graphql`
  query portfolio {
    caseStudies: allFile(
      filter: {
        sourceInstanceName: { eq: "caseStudies" }
        name: { eq: "index" }
      }
      sort: { order: ASC, fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            thumbImg {
              publicURL
            }
            thumbImgAlt
            summary
            order
            industry
          }
          slug
        }
        sourceInstanceName
        relativeDirectory
        name
      }
    }
  }
`
